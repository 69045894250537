<template>
  <section id="faq">
    <!-- seach banner  -->
    <v-card
      flat
      class="faq-bg d-flex align-center justify-center text-center mb-9"
    >
      <v-card-text>
        <p class="faq-title text-2xl font-weight-semibold primary--text mb-2">
          Hello, how can we help?
        </p>
        <p>or choose a category to quickly find the help you need</p>

        <v-form class="faq-search-input mx-auto">
          <v-text-field
            v-model="knowledgeBaseSearchQuery"
            outlined
            placeholder="Ask a question...."
            hide-details
            class="faq-search-input"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card-text class="question-section">
      <v-row>
        <v-col
          v-for="item in filterFaqData"
          :key="item.title"
          md="10"
          offset-md="2"
          cols="12"
          class="mx-auto mb-8"
        >
          <div class="faq-title d-flex align-center">
            <v-avatar
              color="secondary"
              rounded
              size="40"
              class="v-avatar-light-bg"
            >
              <v-icon
                size="24"
                class="text--primary"
              >
                {{ item.icon }}
              </v-icon>
            </v-avatar>

            <div class="ms-4">
              <h2 class="text--primary text-xl font-weight-medium">
                {{ item.title }}
              </h2>
              <p class="mb-0">
                {{ item.subtitle }}
              </p>
            </div>
          </div>

          <div class="faq-question-answers mt-6">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="data in item.qAndA"
                :key="data.question"
              >
                <v-expansion-panel-header class="text-base">
                  {{ data.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ data.answer }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <!-- no result found -->
        <v-col
          v-show="!filterFaqData.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="text-center">
      <p class="text-xl font-weight-medium text--primary mb-1">
        You still have a question?
      </p>
      <p>If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!</p>
    </v-card-text>

    <v-row class="mx-auto">
      <v-col
        v-for="(contact,index) in contactDetails"
        :key="contact.icon"
        cols="12"
        sm="6"
        md="5"
        :class="index > 0 ? 'me-auto': 'ms-auto'"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="24"
            >
              {{ contact.icon }}
            </v-icon>
          </v-avatar>
          <p class="text-xl text--primary font-weight-medium mb-2">
            <a :href="`http://teams.microsoft.com/l/chat/0/0?users=${contact.users}`" v-if="contact.users">
              {{ contact.contact }}
            </a>
            <a :href="`mailto:${contact.contact}`" v-else>
              {{ contact.contact }}
            </a>
          </p>

          <p class="text-sm mb-0">
            {{ contact.tagLine }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
/* eslint-disable operator-linebreak */

import {
  mdiMagnify,
  mdiHelpCircleOutline,
  mdiHubspot,
  mdiBriefcaseVariantOutline,
  mdiChartTimeline,
  mdiReceiptTextOutline,
  mdiFileDocumentMultipleOutline,
  mdiMicrosoftTeams,
  mdiEmailOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  setup() {
    const knowledgeBaseSearchQuery = ref('')

    const faqQuestions = [
      {
        title: 'Common',
        subtitle: 'Most asked questions.',
        icon: mdiHelpCircleOutline,
        qAndA: [
          {
            question: 'How can users be added to Lighthouse?',
            answer:
              'You do not need to contact support for adding users to Lighthouse. Any new member has to connect to Lighthouse using their Microsoft credentials. Once this step is done, their account will automatically be added to the platform.',
          },
          {
            question: 'How can users be added to my Team\'s group?',
            answer:
              'Adding users to Team\s group doesn\'s require the help of the Lighthouse support. This step can be done by going to the group in Teams and adding the new member.',
          },
          {
            question: 'Why can\'t I find someone in the dropdown list?',
            answer:
              'If a user is not in the list, ensure they have logged in into Lighthouse and have updated the different fields (department, Teams group, office) under their profile.',
          },
          {
            question: 'Why can\'t I find certain projects under someone\'s timeline?',
            answer:
              'If projects are missing or not being displayed in your timeline, make sure you have been assigned to the project.',
          },
          {
            question: 'The system doesn\'t let me update the field and report dates in the project page, how can they be updated?',
            answer:
              'All dates need to be update in the Timeline view of the project. This ensures that dates are aligned in both views.',
          },
          {
            question: 'What shall I do if I still see a completed project folder in my Teams Sharepoint?',
            answer:
              'Please check in the completed Sharepoint that your project folder has been copied. If it has, then you can delete it. If not, then contact the support team.',
          },
        ],
      },
      {
        title: 'Hubspot',
        subtitle: 'Hubspot pipelines, stages and deals related questions.',
        icon: mdiHubspot,
        qAndA: [
          {
            question: 'What\'s the different between creating a deal in Hubspot directly or Lighthouse?',
            answer:
              'There are 2 main differences, 1st is ensuring required fields are filled for the different stages. 2nd is you can create a project directly from the Hubspot section in Lighthouse. \
                Otherwise, in terms of information being displayed there is no difference between the Lighthouse Hubspot section and Hubspot.',
          },
          {
            question: 'When selecting a deal owner, why can\'t I find a specific persion?',
            answer:
              'You have to make sure the person was added as a user in Hubspot before. Please contact the Hubspot support team.',
          },
          {
            question: 'I see a Est. Field Start and End fields, what are those for?',
            answer:
              'If you know the estimated timeline for you project, please provide that information. This is very helpful when we try to look at what\'s in the pipeline especially for our field locations scheduling.',
          },
          {
            question: 'In the Company and Contact fields, I see each name being displayed twice, why is that?',
            answer:
              'This is not a bug. BVA has setup a rule that there should be a main Company and Contact per deal on top of the regular Company and Contact. This is why you are seeing the input twice.',
          },
        ],
      },
      {
        title: 'Projects',
        subtitle: 'Project related questions.',
        icon: mdiFileDocumentMultipleOutline,
        qAndA: [
          {
            question: 'How shall I create a project?',
            answer:
              'Most it not all project must be link to an Hubspot deal. Please go to the Hubspot section, open your deal in the closed won stage and click on the create project button. \
              If you do not have a deal, then click on the Add new project in the Project section ',
          },
          {
            question: 'What\'s with all the different numbers at the top of the Project List Page?',
            answer:
              'These numbers are the counts of the different projects in different states from the inception of Lighthouse. Hopefully, it provides a sense of accomplishment. Great work everybody!',
          },
          {
            question: 'What are the different dropdown boxes and the search bar for in the Project List Page?',
            answer:
              'They are the different filters a user can set to display the projects they are interested in. The search bar helps the use find the specific project(s) based on different search fields \
              such as job number, job name, category, client, business initiative, service types, etc.',
          },
          {
            question: 'What is the Action column purpose?',
            answer:
              'From the Project List, a user can either go directly to the project page, timeline page or sharepoint page by clicking on the "...".',
          },
          {
            question: 'How are the START and END dates computed?',
            answer:
              'For any project with either one field or multiple field countries, the START and END date is taken from the earliest field start date and the latest field end date.',
          },
          {
            question: 'I need to update the status of my project, how do I do that?',
            answer:
              'If you have a multi-country study, then the project as a whole will be updated depending on the status of the different field work. In order to update the field work status, click on the edit button in the country box',
          },
          {
            question: 'I see a tasks tab in the project page, how is it different from the timeline?',
            answer:
              'We wanted to provide a more fluid experience in Lighthouse and anything project related can be dealt in the project page. All tasks linked to a project are being listed in the Tasks tab. \
              You can make any update to task there or in the Timeline view.',
          },
        ],
      },
      {
        title: 'Timeline',
        subtitle: 'Timeline and tasks related questions.',
        icon: mdiChartTimeline,
        qAndA: [
          {
            question: 'Why can\'t I find certain projects under someone\'s timeline?',
            answer:
              'If projects are missing or not being displayed in your timeline, make sure you have been assigned to the project.',
          },
          {
            question: 'There has been some delay and I need to move all my project tasks, how can I do that without having to go onto each task and update dates individually?',
            answer:
              'In the Timeline view, click on the project row, you will see the project detail window. At the bottom, you will see a progression bar, move it to the right to move \
              all tasks by the number of days you need and click the udpate button on the right.',
          },
          {
            question: 'What is the show user\'s tasks only checkbox for?',
            answer:
              'By default, all project tasks are being displayed, if you need to only see tasks that have been assigned to a person, then check that box.',
          },
          {
            question: 'I\'m not available on certain days and my team needs to know when so we can readjust certain tasks',
            answer:
              'You can now create a task at the user level. You just need to click a day on the user\s row and create your task. You can your team will be able to see that task in their \
              Timeline view.',
          },
          {
            question: 'I don\'t want to see all projects as some have been set to completed, cancelled or on-hold. How can I remove them from the list?',
            answer:
              'Under the statuses field, select the statuses you are intersted to see in your Timeline.',
          },
          {
            question: 'What is the progression bar use for in the task window',
            answer:
              'You can adjust the progression of the task until it\'s completed so the other members of the team can see where that task stands.',
          },
        ]
      },
      {
        title: 'Purchase Order',
        subtitle: 'Purchase order related questions.',
        icon: mdiReceiptTextOutline,
        qAndA: [
          {
            question: 'What is the process in terms of creating or updating a PO?',
            answer:
            'The process is as follow: Once a project has been created, the Insight team is responsible in creating a PO for every supplier who is going to provide a service, including intercompany services. \
            Once all POs have been created, the PM team is now responsible in updating the amount of every PO to match the final cost. Once an invoice is received, do not forget to upload the invoice to the PO.'
          },
          {
            question: 'How can I create a purchase order for my project?',
            answer:
            'Go to your specifc project page, once there you will see a Purchase Order tab. This is where you can view your current POs as well as creating new ones'
          },
          {
            question: 'I can\'t find my supplier and/or my supplier contact, what shall I do?',
            answer:
            'Contact supplier.support@prs-invivo-group.com and let them know your supplier is missing. You will need to provide certain information such as company name, type of supplier (online panel, coding, etc.), name and email address of your contact. \
            If you can provide the full address and country the better.'
          },
          {
            question: 'My office tax id or organization number is wrong, who shall I contact to correct it?',
            answer:
            'Contact support.lighthouse@prs-invivo-group.com and let them know your supplier is missing. You will need to provide certain information such as company name, type of supplier (online panel, coding, etc.), name and email address of your contact. \
            If you can provide the full address and country the better.'
          },
          {
            question: 'I need to update a PO, what can\'t I update my specific item?',
            answer:
            'Once a PO has been created, the specific items/rows cannot be updated. You can add a new item and adjust the amount so the total amount matches the new price. Do not forget to add a description.'
          },
          {
            question: 'How can I save the PO as a pdf?',
            answer:
            'In the preview mode, click on the Print button and select "Save as PDF" under the destination field.'
          },
        ]
      }
    ]

    const contactDetails = [
      {
        icon: mdiMicrosoftTeams,
        contact: '@Hideo & @Max',
        tagLine: 'We are always happy to help!',
        users: 'hideo.maldonado@prs-invivo-group.com,max.zalewski@prs-invivo-group.com',
        support: 'Teams'
      },
      {
        icon: mdiEmailOutline,
        contact: 'support.lighthouse@prs-invivo-group.com',
        tagLine: 'Best way to get answer faster!',
        support: 'email'
      },
    ]
    const filterFaqData = computed(() => {
      const knowledgeBaseSearchQueryLower = knowledgeBaseSearchQuery.value.toLowerCase()

      return faqQuestions.filter(
        item =>
          // eslint-disable-next-line implicit-arrow-linebreak
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.qAndA.filter(queObj => queObj.question.toLowerCase().includes(knowledgeBaseSearchQueryLower) || queObj.answer.toLowerCase().includes(knowledgeBaseSearchQueryLower)).length,
      )
    })

    return {
      faqQuestions,
      knowledgeBaseSearchQuery,
      filterFaqData,
      contactDetails,
      icons: { mdiMagnify },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/faq.scss';
</style>
